import CMFilterDTO from '@/app/admin/modules/course_managment/core/DTO/cm-filters.dto';
import { FILTERS_ARGS } from "@/app/admin/modules/course_managment/core/cm-const";
import { getMultipleQueryValue, getMultipleQueryValueWithNumber } from '@/util';

export default class CourseDayFiltersModel {

  constructor(filters = {}) {
    this.filters = new CMFilterDTO(filters);
    this.labels = getMultipleQueryValue(filters.labels);
    this.labelsExclude = getMultipleQueryValue(filters.labels_exclude);
    this.customLabels = getMultipleQueryValueWithNumber(filters.custom_labels);
    this.customLabelsExclude = getMultipleQueryValueWithNumber(filters.custom_labels_exclude);
  }

  getApiData() {
    return {
      [FILTERS_ARGS.EXAM_DATE_BEFORE]: this.filters.examDateBefore,
      [FILTERS_ARGS.EXAM_DATE_AFTER]: this.filters.examDateAfter,
      [FILTERS_ARGS.EXAM_LOCATION]: this.filters.examLocation,
      refunded: this.filters.refunded === null ? undefined : this.filters.refunded,
      cbr_user: this.filters.cbr_user,
      labels: this.labels,
      labels_exclude: this.labelsExclude,
      custom_labels: this.customLabels,
      custom_labels_exclude: this.customLabelsExclude,
    };
  }
}
