<template lang="pug">
  .filters
    portal(to="courseday-filters-chips")
      filters-chips(
        :model="model"
        portal-chip-name="courseday-filters-chips"
        @remove="removeFilter"
        @remove:multiple="removeFilterMultiple"
      )
    portal(to="cd-head-actions")
      .actions__wrapper
        labels(
          :items="labelItems"
          ref="labels" itemValue="key"
          :value="labels"
          :disabled="!$route.query.course_day"
          @changed="setLabels"
          @changed:exclude="setLabelsExclude"
        )
        customLabels(
          :value="selectedCustomLabels"
          :disabled="!$route.query.course_day"
          @changed="setCustomLabels"
          @changed:exclude="setCustomLabelsExclude"
        )
        v-select(
          :value="model.filters.refunded"
          :items="refundedOptions"
          item-value="key"
          item-text="label"
          placeholder="Is payment refunded"
          :disabled="!$route.query.course_day"
          hide-details
          @input="updateRefunded"
        ).mt-0.filters-courseday__select
    .filters-courseday(v-show="everyLabels.length")
      div(v-for="(labelSection, index) in everyLabels" :key="`${index}${$route.name}`").d-flex
        div(v-for="(item, index) in labelSection").mr-2.mt-1.mb-1
          app-label(
            v-if="labelsMap[item]"
            :keyValue="item"
            :label="labelsMap[item].label"
            :isBtn="false"
            :color="labelsMap[item].color"
          )
            template(v-slot:prepend="labelproprs" v-if="labelsMap[item].exclude")
              v-icon(x-small color="white").mr-1.block mdi-alert-outline
    filters-list(:filters="filters" @filterChanged="startFiltering")
</template>

<script>
import { mapGetters } from 'vuex';
import Model from '../core/courseDayFilters.model';
import { SYSTEM_LABELS } from '../../candidates/core/candidates-const';

export default {
  data: () => ({
    model: {},
    filters: {
      cbr_user: null,
      examLocation: '',
      examDateBefore: '',
      examDateAfter: ''
    },
    labelItems: SYSTEM_LABELS,
    refundedOptions: [
      {key: null, label: 'All payments'},
      {key: true, label: 'Refunded'}
    ]
  }),

  inject: ['getDefaultPagination'],

  computed: {
    ...mapGetters({
      customLabels: 'labels/items'
    }),
    labelsMap() {
      let items = {}
      this.labelItems.forEach(item => {
        items[item.key] = {
          label: item.label,
          color: item.color,
          exclude: this.model.labels.length ? false : true
        }
      })
      if (!this.customLabels) return items
      this.customLabels.forEach(item => {
        items[item.ID] = {
          label: item.name,
          color: item.color,
          exclude: this.model.customLabels.length ? false : true
        }
      })
      return items
    },

    everyLabels() {
      let labels = []
      let everyLabels = [...this.labels, ...this.selectedCustomLabels]
      everyLabels.forEach((label, index) => {
        if (index % 2 == 0) {
          labels.push([label])
          return
        }
        labels[labels.length - 1].push(label)
      })
      return labels.reverse()
    },

    labels() {
      if (this.model.labelsExclude.length) return this.model.labelsExclude
      return this.model.labels
    },

    selectedCustomLabels() {
      if (this.model.customLabelsExclude.length) return this.model.customLabelsExclude
      return this.model.customLabels
    },
  },

  created() {
    this.initFilters()
  },

  methods: {
    initFilters() {
      this.model = new Model(this.$route.query);
      this.setFilters();
    },

    async startFiltering() {
      this.setFiltersToModel();
      await this.setQuery();
    },

    setFiltersToModel() {
      for (let filter in this.filters) {
        if (filter in this.model.filters) {
          this.model.filters[filter] = this.filters[filter]
        }
      }
    },

    async setQuery() {
      await this.$router.replace({query: {...this.$route.query, ...this.model.getApiData(), page: 1}});
      this.$emit('filterChanged');
    },

    setFilters() {
      for (let filter in this.filters) {
        if (filter in this.model.filters) {
          this.filters[filter] = this.model.filters[filter]
        }
      }
    },

    removeFilter(field) {
      this.filters[field] = undefined;
      this.model.filters[field] = undefined;
      this.setQuery()
    },

    removeFilterMultiple(field) {
      this.filters[field] = [];
      this.model.filters[field] = [];
      this.setQuery()
    },

    setLabels(labels) {
      this.model.labels = [...labels]
      this.model.labelsExclude = []
      this.setQuery()
    },

    setLabelsExclude(labels) {
      this.model.labels = []
      this.model.labelsExclude = [...labels]
      this.setQuery()
    },

    setCustomLabels(labels) {
      this.model.customLabels = [...labels]
      this.model.customLabelsExclude = []
      this.setQuery()
    },

    setCustomLabelsExclude(labels) {
      this.model.customLabels = []
      this.model.customLabelsExclude = [...labels]
      this.setQuery()
    },
    updateRefunded(val) {
      this.model.filters.refunded = val
      this.setQuery()
    }
  },
  components: {
    FiltersList: () => import('./CourseDayFiltersList.vue'),
    FiltersChips: () => import('./CourseDayFiltersChips.vue'),
    labels: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesLabels.vue'),
    customLabels: () => import('@/app/admin/modules/candidates/components/CandidatesCustomLabels.vue'),
    appLabel: () => import('@/components/global/Label.vue')
  }
}
</script>

<style lang="scss">
.filters-courseday {
  padding: 5px 10px;
  border-bottom: 1px solid $border-color;

  &__select {
    display: flex;
    align-items: center;
    margin-top: 0;
    padding-top: 0;
    max-width: 150px;

    input {
      padding: 0;
    }
  }
}

.actions__wrapper {
  display: flex;
  @include media("<=tablet") {
    button {
      padding: 0 !important;
      span {
        font-size: 13px;
      }
    }
  }
}
</style>
